#two-column-layout{
    display: flex;

    @media (max-width: $medium-screen - 1) {
        flex-direction: column;
    }
}

#nav-left{
    width: 300px;
    padding: 60px 10px 10px 10px;

    h2{
        text-align: left;
        font-weight: bold;
    }

    .option-input-normal{
        text-align: left;
        .option-container{
            display: block;
        }
    }

    .selected{
        font-weight: bold;
    }

    .tag-button, .news-button{
        padding: 2px 5px;
        background-color: lightgrey;
        margin-bottom: 2px;

        &.selected{
            background-color: lightblue;
        }
    }

    ul{
        list-style: none;
        padding: 0;
        text-align: left;

        ul{
            list-style-type: circle;
            margin-left: 18px;

            ul{
                list-style-type: none;
                padding: 0;
                margin-bottom: 10px;

                &.tag-list, &.news-list{
                    margin-left: -18px;
                }
            }
        }
    }

    li{
        cursor: pointer;
    }

    #filter-header{
        display: none;
    }

    @media screen and (max-width: $medium-screen) {
        @include base-field-border("full");

        border: 1px solid $middle-color3;
        margin-top: $default-margin;
        width: auto;
        padding: 0 20px 20px 20px;
        cursor: pointer;

        #filter-header{
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
    }
}

#content-column{
    padding: 0;
}