#simple-message{
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index:9999999;
    top: 0;
    left: 0;

    .message-background{
        display: block;
        position: fixed;
        height: 100vh;
        width: 100%;

        background-color: $primary-color1;
        opacity: 0.5;
        text-align: center;
    }

    .message-container{
        display: table;
        position: fixed;
        width: 100%;
        height: 100vh;
        
        .message-cell{
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .message-box{
            @include base-button-border();
            display: inline-block;
            padding: $space20;
            background-color: $contrast-color2;

            .title{
                font: $simple-message-font1-bold;
                color: $primary-color1;
            }

            .body{
                font: $body-font1;
                color: $middle-color2;
                margin-bottom: $space20;
            }

            .ok-button{
                background-color: $primary-color1;
            }
        }
    }
}