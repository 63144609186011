$news-description-font: $body-font1;
$news-component-width: 196px;
$news-image-form-width: 150px;

#content-container{
    @include DefaultContainerMaxWidth($news-component-width);
}

#news-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($news-component-width);
}


.news-container > div{
    padding: 0 $space20;

    &.news-note{
        padding: 0;

        .news-note-content{
            padding: $space20 $space20 0;
        }
    }
}

.news-container{
    display: block;
    width: 100%;
    overflow: hidden;

    color: $description-font-color;
    font: $news-description-font;
    text-align: left;

    margin-top: $list-margin-top;
    padding: 0 0 15px 0;

    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
    }

    box-sizing: border-box;

    .news-id{
        font: Bold $large-font2;
        padding: 12px 20px;
        background: amplifyColor($line-decoration-color, 5);

        a{
            color: $contrast-color2;
            text-decoration: none;

            &:hover{
                color: amplifyColor($contrast-color2, 10);
            }

            flex:1;
        }

        .name{
            text-align: left;
        }

        .news-time{
            color: $secondary-color2;
            font-size: 80%;
        }

        .parent{
            background-color: $contrast-color2;
            font: bold $body-font2;
            color: $primary-color2;
            font-style: italic;
            border-radius: 8px;
            padding: 2px 10px;
            text-align: right;
        }

    }

    .news-tags{
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;

        .tag-item{
            padding: 5px 20px;
            background-color: $contrast-color1;
            color: $primary-color1;
            margin-left: 5px;
            font-style: italic;
        }
    }

    .news-options{
        margin-top: 5px;
        float: right;

        .status{
            margin-left: 5px;
        }
    }

    .news-description{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
        padding: 0;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        margin-top: $default-margin;
    }

    .sun-editor-content{
        overflow: scroll;
        margin-top: 5px !important;
    }
}

#form-container{
    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        margin-top: $default-margin;
    }
}