select{
    -webkit-appearance: none;
    -moz-appearance: none;
    @include base-field-background("none");
    font: $input-font1;
    width: $base-field-width;
    height: $base-field-height;
    color: $input-text-color;
    vertical-align:top;
    padding-left: $base-field-left-padding;

    background: url(./../../img/dropdown-arrow-dark.png);
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.pobTypeSelection {
    padding-right: 40px;
}