#tnial-view-container {
    h3 {
        margin: 0;
    }
    .rowflex {
        position: relative;
        display: flex;
        margin: 0;
        padding: 20px;
        justify-content: space-between;
        gap: 10px;
    }

    .col {
        position: relative;
        width: 100%;

        &.left {
            text-align: left;
        }

        &.right {
            width: 400px;
            padding: 10px;

            border: 1px solid $middle-color2;
            border-radius: 5px;
        }
    }

    table.normal {
        width: 100%;

        tr{
            vertical-align: top;

            td:first-child {
                text-align: left;
                font-weight: bold;
            }

            td:last-child {
                width: 50%;
                text-align: left;
            }
        }
    }

    table.normal2 {
        width: 100%;

        td{
            padding-block: 5px;
        }

        tr{
            td:nth-child(odd) {
                font-weight: bold;
                width: 200px;
            }
        }
    }

    ul {
        margin: 0;
        padding-left: 16px;
    }

    .section {
        width: 100%;
        background-color: rgb(147, 177, 179);
        margin-block: 10px 5px;
        font-weight: bold;
    }

    .description {
        padding: 10px;
        border: 1px solid $middle-color2;
        border-radius: 5px;
    }
}