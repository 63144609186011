$latestStatus-description-font: $body-font1;
$latestStatus-component-width: 100%;

#latestStatus-content-container{
    width: $latestStatus-component-width;

    .pagination-component{
        width: $latestStatus-component-width;
        max-width: $latestStatus-component-width;
    }
}

.latestStatus-delete{
    position: absolute;
    cursor: pointer;
    
    width: 20px;
    height: 20px;
    top: 10px;
    right: 20px;

    img{
        width: 100%;
        height: 100%;
    }
}

#latestStatus-list{
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    color: $normal-text-color;
    width: $latestStatus-component-width;

    #form-container{
        display: block;
        padding: 0;
        margin-top: 0;

        input[type="text"]{
        &.time{
            border: none;
            border-radius: 0;
            font-weight: bold;
            font-size: 140%;
            background-color: $primary-color2;
        }}

        .sun-editor, input[type="text"]{
            background-color: transparent;
            box-sizing: border-box;

            &:not(.time):hover{
                @include base-field-border("full");
            }
        }
    }
}

.latestStatus-container{
    display: inline-block;
    height: max-content;
    width: $latestStatus-component-width;

    flex: 1;
    flex-basis: 100%;

    color: $description-font-color;
    font: $latestStatus-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    padding-top: 7px;

    .latestStatus-when, .latestStatus-where{
        flex: 1;
    }

    .latestStatus-with{
        font: $medium-font2-bold;
        margin-right: 15px;
    }

    .latestStatus-description{
        padding: 8px 12px;
        margin-bottom: 0;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }

    .rowActionPlan{
        background-color: $primary-color2;

        .center{
            align-items: center;
        }
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color);
        border-width: 1px;
        position: relative;
        top: auto;
    }

    .rowflex{
        display: flex;

        &.center{
            align-items: center;
        }

        &.latestStatus-description{
            gap: $space20;
        }
        
        &.latestStatus-actionPlanList{
            padding: $space20/2;
            gap: $space20;
            flex-wrap: wrap;

            .col{
                width: auto !important;
            }

            @media(max-width: 1120px){
                justify-content: center;
                gap: $space20*2;
            }

            .actionPlanSection {
                border: 2px solid $line-decoration-color;
                border-radius: 5px;
                padding: $space20/2;
                
                @media (max-width: 699px) { // should be max-width: $small-screen - 1, but not working for unknown reason
                    margin-top: $space20;
                    max-width: 100%;
                }
            }
        }

        .col{
            &.default-margin-left{
                margin-left: 0;
            }

            &.left{
                flex: 0.5;
            }

            &.right{
                flex: 0.5;

                @media(max-width: 1120px){
                    flex: 1;  
                }
            }
        }
    }
    
    .latestStatus-actionPlan{
        display: flex;
        align-items: center;
        font-weight: bold;

        .icon{
            display: inline-flex;
            
            img{
                height: auto !important;
                margin-right: 8px;
            }
            
        }
    }
}

.input-container.actionPlan{
    display: flex;

    .addActionPlan{
        width: 40px;
        border-radius: 20px;
        margin-left: 15px;
    }
}

#latestStatus-add, #latestStatus-edit{
    #form-container{
        margin-top: 140px;
        display: block;

        .rowflex{
            flex-wrap: wrap;
            gap: $space20;

            .col{
                flex: 1;
            }
        }

        .sun-editor, input[type="text"]{
            background-color: transparent;
            box-sizing: border-box;

            &:not(.time):hover{
                @include base-field-border("full");
            }
        }

        input[type="text"]{
            &.time{
                border: none;
                border-radius: 0;
                font-weight: bold;
                font-size: 140%;
                background-color: $primary-color2;
            }
        }
    }
}

#actionPlanSectionContainer{
    .actionPlan-delete {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}

#floater-nav{
    position: fixed;
    left: 0;
    top: 30%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    z-index: 11000;
    background-color: $middle-color1;
    padding: 2px;
    border-radius: 5px;

    .floater-nav-item{
        width: 30px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: $primary-color1;
        text-decoration: none;
        font-weight: bold;
        color: $normal-text-color;

        .overview{
            display: block;
        }
        .detail{
            display: none;
        }

        // &:hover{
        //     width: 200px;

        //     .overview{
        //         display: none;
        //     }

        //     .detail{
        //         display: block;
        //     }
        // }
    }

    &:hover{
        .floater-nav-item{
            width: 200px;

            .overview{
                display: none;
            }

            .detail{
                display: block;
            }
        }
    }
}