@mixin base-placeholder-color{
    &::placeholder{
        color: $input-placeholder-color;
    }
}

div.input-container{
    position: relative;

    .icon{
        position: absolute;
        display: block;

        @include center(60px, $base-field-height, $center-height-fix);

        img{
            width: $field-icon-width;
            filter: iconFilter();
        }
    }
}

input{
    &[type="text"], &[type="password"]{
        @include base-placeholder-color();
        font: $input-font1;
        min-width: auto;
        
        width: 100%;
        height: $base-field-height;
        color: $input-text-color;
        
        &.text-input{
            @include base-field-background();
            padding-left: 60px;

            &.no-icon{
                padding-left: $base-field-left-padding;
            }
        }

        &.search-input{
            @include base-field-background("left");
            padding-left: $base-field-left-padding;
        }
    }
}

textarea {
    @include base-placeholder-color();
    @include base-field-background();
    font: $input-font1;
    min-width: auto;
    
    width: 100%;
    height: $base-field-height;
    color: $input-text-color;
    padding-inline: $base-field-left-padding;


}

.textarea {
    @include base-placeholder-color();
    @include base-field-background();
    font: $input-font1;
    min-width: auto;
    
    width: 100%;
    color: $input-text-color;
    padding-inline: $base-field-left-padding;
}

div.simple-select-component{
    position: relative;

    .icon-container{
        display: flex;
        align-items: center;
        position: absolute;
        height: 40px;
        width: 20px;

        top: 0;
        right: $space20;

        img.dropdownIcon{
            width: 20px;
            height: 20px;
        }
    }
}

div.search-input{
    display: inline-block;
    flex: 1;
    @include base-placeholder-color();
    font: $input-font1;

    min-height: $base-field-height;
    color: $input-text-color;
    @include base-field-background("left");
    padding-left: $base-field-left-padding;

    &.no-border{
        border-radius: 0;
    }

    .si__control{
        background-color: transparent;
        border: none;
        border-radius: 0;
        overflow: hidden;
    }

    .si__value-container{
        padding: 0;
        height: $base-field-height - 2;
        overflow: scroll;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .css-b8ldur-Input{
            margin: 0;
            padding: 0;
        }

        .si__input{
            color: $input-text-color;
        }

        .si__single-value{
            color: $input-text-color;
        }

        .si__multi-value{
            background-color: $middle-color2;

            .si__multi-value__label{
                color: $contrast-color2;
            }
        }

        .si__placeholder{
            font: $input-font1;
        }
    }

    .si__indicator-separator{
        display: none;
    }

    .si__dropdown-indicator{
        display: none;
    }

    .si__menu{
        color: $contrast-color2;
        margin-left: -$space20;
        border-radius: 8px;

        .si__menu-list{
            background-color: $middle-color2;
            border-radius: 8px;
        }

        .si__option{
            background-color: $middle-color2;

            &.si__option--is-focused{
                background-color: reduceColor($middle-color2, 10);
            }
        }
    }
}