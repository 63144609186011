$decoration-height: 60px;
$nav-menu-font: $large-font2;
$h1-font: $extra-large-font2;
$list-margin-top: 23px;

body{
    background-color: $background-color;
}

h2{
    color: $normal-text-color;
    margin-bottom: 0;

    &:not(:first-child){
        margin-top: 40px;
    }
}

header{
    position: fixed;
    z-index: 9999;
}

footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index:2;

    span{
        position: absolute;
        @include center(100%, $footer-decoration-height);
        font: $footer-font;
        color: $normal-text-color;
    }
}

.decoration{
    @include decoration($decoration-height);
    position: fixed;
    top: 0px;

    @media(max-width: $medium-screen - 1){
        @include decoration(2*$decoration-height);
    }
}

.decoration-footer{
    @include decoration($footer-decoration-height);
}

.line-decoration{
    @include line-decoration($line-decoration-color);
    position: fixed;

    top: $decoration-height + 4px;

    @media (max-width: $medium-screen - 1){
        top: $decoration-height - 2px;
    }
}

.line-decoration-footer{
    @include line-decoration($line-decoration-color);
    position: absolute;

    bottom: $footer-decoration-height + 4px;
}

header{
    .decoration{
        z-index: 8001;
    }

    .line-decoration{
        z-index: 8000;
    }

    h1{
        font: $h1-font;
        color: $normal-text-color;
        position: absolute;
        margin: 0;
        left: $title-left-margin;
        @include v_center($decoration-height);
    }
    
    #nav-logo{
        position: absolute;
        height: $logo-height;
        left: $left-margin;
        display: inline-block;
        @include v_center($decoration-height);
    }
    
    #NavigationHeaderMenu{
        float: right;
        position: relative;
    
        @media(max-width: $medium-screen - 1){
            margin-top: $decoration-height;
            float: left;
        }
    
        ul{
            list-style: none;
            list-style-type: none;
    
            li{
                cursor: pointer;
                padding-left: 40px;
                display: inline-table;
                
                @media (max-width: $small-screen - 1) {
                    padding-left: 20px;
                }

                &:first-child{
                    padding-left: 0;
                }
    
                a{
                    display: inherit;
                    text-decoration: none;
    
                    img{
                        display: table-cell;
                        width: $nav-icon-height;
                    }
                    span{
                        padding-left: 4px;
                        display: table-cell;
                        vertical-align: middle;
                        color: $normal-text-color;
                        font: $nav-menu-font;

                        @media (max-width: $small-screen - 1) {
                            font: $nav-small-font;
                        }
                    }
        
                }
                
                &:last-child{
                    padding-right: $default-margin;
                }
            }
        }

        #MoreNavigationMenuWrapper{
            display: block;
            position: fixed;

            top: 50px;
            @media(max-width: $medium-screen - 1){
                top: 100px;
            }
            @media(min-width: $medium-screen){
                right: 0;
            }
            @media(max-width: $medium-screen - 1){
                left: 0;
            }
            width: 340px;
            height: max-content;

            padding-top: 18px;
            padding-bottom: 15px;
            background-color: transparent;

            &.hidden{
                display: none;
            }

            #MoreNavigationMenu{
                display: flex;
                position: relative;
    
                top: 0;
                right: 0;
                width: 340px;
                height: max-content;
    
                padding-bottom: 15px;
                background-color: $background-color2;
                box-shadow: -2px 3px 10px #00000029;
                border-radius: 0px 0px 0px 4px;
            
                flex-wrap: wrap;
    
                div{
                    cursor: pointer;
                    padding-top: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-bottom: 0px;
        
                    a{
                        text-decoration: none;
                        display: flex;
                        align-items: center;
        
                        img{
                            width: $nav-icon-height;
                        }
                        span{
                            padding-left: 6px;
                            color: $normal-text-color;
                            font: $nav-menu-font;
                        }
            
                    }

                    &.separator{
                        @include line-decoration($line-decoration-color, 1px);
                        padding: 0;
                        margin: 15px 15px 0 15px;
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
        
}

.content{
    position: relative;
    text-align: center;
    margin-top: $content-top-margin;

    @media (max-width: $medium-screen - 1){
        margin-top: $content-top-margin + $decoration-height;
    }

    margin-left: $default-margin;
    margin-right: $default-margin;

    padding-bottom: 60px;
}

.clear{
    float: none;
    clear: both;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: $background-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $line-decoration-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: reduceColor($line-decoration-color, 10);
}