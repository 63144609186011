$actionPlan-description-font: $body-font1;
$actionPlan-component-width: (($big-screen - (2*$default-margin)) / 2) - $space20;

#content-container{
    @include DefaultContainerMaxWidth($actionPlan-component-width);
}

#actionPlan-list{
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    color: $normal-text-color;

    @include DefaultContainerMaxWidth($actionPlan-component-width);
}

.actionPlan-container{
    display: inline-block;
    height: max-content;
    width: $actionPlan-component-width;

    @media(min-width: $big-screen){
        &:nth-child(2n){
            margin-left: $default-margin;
        }
    }
    
    @media(max-width: $big-screen - 1){
        flex: 1;
        flex-basis: 100%;
    }

    color: $description-font-color;
    font: $actionPlan-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    .actionPlan-header{
        display: flex;
        background-color: $line-decoration-color;
        height: $base-field-height;
        align-items: center;

        padding-left: 12px;
        padding-right: 12px;

        .icon{
            height: 20px;

            img{
                width: auto;
                height: 20px;
            }
        }
        
        .date{
            flex: 1;
            padding-left: 15px;
            font-weight: bold;
        }
    }

    .actionPlan-name{
        font: $medium-font2-bold;
    }

    .actionPlan-with{
        font: $medium-font2-bold;
        margin-top: 15px;
    }

    .actionPlan-description{
        padding: 8px 12px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }

    .actionPlan-location{
        display: flex;
        align-items: center;
        
        img{
            margin-right: 5px;    
        }
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color);
        border-width: 1px;
        position: relative;
        top: auto;
    }

    .rowflex{
        display: flex;
        align-items: center;
        padding: 5px 15px;

        .actionPlan-project{
            flex: 1;
            display: flex;
            align-items: center;

            .icon{
                height: 15px;

                img{
                    height: 15px;
                }
            }

            .project{
                margin-left: 15px;
                flex: 1;
                font-weight: bold;
            }
        }

        .actionPlan-options{

            :not(:first-child){
                margin-left: 5px;
                width: 20px;
                height: 20px;
            }
        }
    }
    
}


#form-container{
    .selectionStyle{
        &.full-width{

            .si__multi-value{
                flex-basis: 100%;
                padding: 10px;
            }
        }

        &.auto-height{
            .si__value-container{
                height: auto;
                overflow: auto;
                padding-top: $space20;

                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                .si__placeholder{
                    font: $input-font1;
                }
            }
        }
    }
}

#action-plan-add{
    #form-container{
        .selectionStyle{
            background-color: $background-color-input;
        }
    }
}

.actionPlan-result{
    position: relative;
    display: flex;
    align-items: center;

    .background{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        background-color: $primary-color1;
        opacity: 0.5;

        align-items: center;
        z-index: 8002;
    }

    #form-container{
        @include base-field-background();
        position: fixed;
        width: auto;
        max-width: 540px;
        height: max-content;
        text-align: center;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding: $space20;

        z-index: 8003;

        color: $contrast-color2;

        #header{
            display: flex;
            align-items: center;
            
            #title{
                flex: 1;
                font: $extra-large-font2;
                text-align: left;
            }
    
            #date{
                flex: 1;
                text-align: right;
            }
        }
        

        #actionPlan-resultType{
            display: flex;
            width: 100%;

            div{
                background-color: $background-color;
                flex: 1;
                text-align: center;
                padding: 10px;
                margin-top: 15px;
                cursor: pointer;

                &:hover{
                    background-color: reduceColor($background-color, 10);
                }    
            }
        }

        .sun-editor{
            max-height: 300px;
            overflow-y: scroll;
        }
    }
}

// ACTION PLAN POST RESULT
#actionPlan-formContent{
    .file-to-upload {
        font-style: italic;
        font-weight: bold;
        margin-bottom: 2px;
        display: inline-block;
        padding: 5px 10px;
        background-color: $primary-color1;
        margin: 2px 5px;
        border-radius: 8px;
    }
}

#actionplan-result-progressbar-container{
    .progress, .total{
        display: inline;
        font-weight: bold;
    }

    .bar-container{
        position: relative;
        width: 100%;
        height: 60px;
        padding: 5px;
        background-color: $middle-color1;
        border-radius: 8px;
        box-sizing: border-box;

        .bar-progress-width{
            height: 100%;
            background-color: amplifyColor($secondary-color, 30);
            border-radius: 8px;
            box-sizing: border-box;
        }

        .progress-detail{
            position: absolute;
            margin: auto;
            top: 13px;
            width: 100%;
            box-sizing: border-box;
        }
    }
}

.actionPlan-objective{
    p {
        margin: 0;
    }
}

.no-googleCalendar-tag{
    background-color: rgb(255, 95, 95);
    color: white;
    padding: 5px 12px;
}

.googleCalendar-tag{
    background-color: rgb(0, 204, 0);
    color: white;
    padding: 5px 12px;
}