$primary-color1: #FFF;
$primary-color2: #EEE;

$middle-color1: #BBBBBB;
$middle-color2: #AAAAAA;
$middle-color3: #666666;

$contrast-color1: #555555;
$contrast-color2: #363636;

$box-shadow-color: #0000000A;

$background-color: $primary-color1;
$background-color2: $primary-color2;
$line-decoration-color: $middle-color1;
$base-button-border-color: $middle-color3;
$normal-text-color: $middle-color3;

$background-color-input: $primary-color2;

$input-border-color: $primary-color2;
$button-background-color: $middle-color2;
$base-button-text-color: $contrast-color1;
$input-placeholder-color: $middle-color2;

$pagination-text-color: $contrast-color2;
$description-font-color: $contrast-color2;

$secondary-color: coral;
$secondary-color2: darkgreen;
$secondary-color3: lightred;

@function amplifyColor($color, $by){
    @return lighten($color, $by);
}

@function reduceColor($color, $by){
    @return darken($color, $by);
}

@function iconFilter(){
    @return invert(1);
}