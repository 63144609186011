$client-description-font: $body-font1;
$client-component-width: 362px;
$client-image-form-width: 150px;
$client-image-form-height: 143px;

#content-container{
    @include DefaultContainerMaxWidth($client-component-width);
}

#client-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include DefaultContainerMaxWidth($client-component-width);
}

.client-container{
    width: $client-component-width;
    margin-left: $default-margin;
    margin-right: $default-margin;
    
    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
    }
}

.client-container-left{
    height: max-content;
    width: $client-component-width;
    overflow: hidden;
    
    @media(max-width: $medium-screen - 1){
        flex: 1;
    }

    color: $description-font-color;
    font: $client-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    img{
        width: $client-component-width;
    }

    .client-description{
        padding: 8px 12px;
    }

    .client-options{
        margin-top: 5px;
        float: right;

        .status{
            margin-left: 5px;
        }

        .button{
            background: $button-background-color;
            border: none;
            box-sizing: border-box;
            font-weight: bold;
            border-radius: 8px;

            height: 20px;
            margin: 0;
            float: left;
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 0;
    }

    .client-phone{
        font-weight: bold;
    }

    .client-id, .client-position, .client-address-title{
        font-weight: bold;
        padding: 10px 20px;
        background-color: amplifyColor($line-decoration-color, 5);
    }

    .client-pic-name{
        font-weight: bold;
    }

}

#form-container{
    .col.clientProfile{
        width: $client-image-form-width;

        img.client-profile-photo{
            cursor: pointer;
            width: $client-image-form-width;
            height: $client-image-form-height;
            object-fit: cover;
            border-radius: $full-border-radius;
        }
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }
}