.jodit-container{
    @include base-field-border("full");
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;

    .jodit-workplace, .jodit .jodit-workplace{
        overflow: hidden !important;
    }

    .jodit-wysiwyg{
        min-height: 40px;
    }

    button:disabled{
        background-color: transparent;
    }
}

.jodit-toolbar-button{
    button:disabled{
        background-color: transparent;
    }
}

.jodit-style2{
    z-index: 1;
    .jodit-container{
        padding-left: 0;
        padding-right: 0;
    }
}