$full-border-radius: 8px;
$left-border-radius: 8px 0px 0px 8px;
$zero-border-radius: 0px;
$right-border-radius: 0px 8px 8px 0px;

@mixin base-field-border($border-position){
    border: 1px solid $input-border-color;

    @if($border-position == "left"){
        border-radius: $left-border-radius;
    }
    @else if($border-position == "none"){
        border-radius: $zero-border-radius;
    }
    @else if($border-position == "right"){
        border-radius: $right-border-radius;
    }
    @else{
        border-radius: $full-border-radius;
    }
}

.left-border{
    @include base-field-border("left");
}

.right-border{
    @include base-field-border("right");
}

.full-border{
    @include base-field-border("full");
}

.no-border{
    @include base-field-border("none");
}