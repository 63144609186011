$member-description-font: $body-font1;
$member-component-width: 196px;

#content-container{
    @include DefaultContainerMaxWidth($member-component-width);
}

#member-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($member-component-width);
}

.member-container{
    display: inline-block;
    color: $description-font-color;
    font: $member-description-font;
    text-align: left;

    margin-left: $space20 + 3px;
    margin-top: $list-margin-top;
    vertical-align: top;

    &:nth-child(1){
        margin-left: 0px;
    }
    
    @media(max-width: $small-screen - 1){
        margin-left: 0px;
    }

    @media(min-width: $small-screen) and (max-width: $medium-screen - 1){
        &:nth-child(2n+1){
            margin-left: 0px;
        }
    }

    @media(min-width: $medium-screen) and (max-width: $big-screen - 1){
        &:nth-child(3n+1){
            margin-left: 0px;
        }
    }

    @media(min-width: $big-screen){
        &:nth-child(4n+1){
            margin-left: 0px;
        }
    }    
    

    img{
        width: $member-component-width;
        height: 187px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    .member-description{
        padding: 8px 12px;
        max-width: $member-component-width;
    }

    .member-name{
        font-weight: bolder;
        margin-bottom: 15px;
    }

    .member-position{
        font-weight: bolder;
        font-size: 110%;
        color: $secondary-color2;
    }

    .member-options{
        margin-top: 5px;
        float: right;

        .status{
            margin-left: 5px;
        }
    }
}

#form-container{
    img.member-profile-photo{
        cursor: pointer;   
        width: 196px;
        height: 187px;
        object-fit: cover;
        border-radius: $full-border-radius;
    }
}