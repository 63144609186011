$base-field-width: 380px;
$search-button-width: 114px;
$base-field-height: $base-component-height;
$base-field-left-padding: $space20;

$input-text-color: reduceColor($input-placeholder-color, 50%);

$field-icon-width: 15px;
$center-height-fix: 4px;

$min-button-width: 160px;

//font
$base-button-font: $medium-font1-bold;

@mixin base-button-border{
    border: 1px solid $base-button-border-color;
    border-radius: $full-border-radius;
}

@mixin base-field-background($border-profile: "full"){
    @include base-field-border($border-profile);
    background: $background-color-input 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $box-shadow-color;
}

@mixin selectionStyle{
    margin-top: 0;

    .si__control{
        background-color: transparent;
        border: none;
        border-radius: 0;
        overflow: hidden;
    }

    .si__value-container{
        padding: 0;
        padding-left: $base-field-left-padding;
        height: $base-field-height - 2;
        overflow: scroll;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .css-b8ldur-Input{
            margin: 0;
            padding: 0;
        }

        .si__input{
            color: $input-text-color;
        }

        .si__multi-value{
            background-color: $middle-color2;

            .si__multi-value__label{
                color: $contrast-color2;
            }
        }

        .si__single-value{
            color: $input-text-color;
        }

        .si__placeholder{
            font: $input-font1;
        }
    }

    .si__indicator-separator{
        display: none;
    }

    .si__dropdown-indicator{
        display: none;
    }

    .si__menu{
        color: $contrast-color2;
        border-radius: 8px;

        .si__menu-list{
            background-color: $middle-color2;
            border-radius: 8px;
        }

        .si__option{
            background-color: $middle-color2;

            &.si__option--is-focused{
                background-color: reduceColor($middle-color2, 10) !important;
            }
        }
    }
}

input, textarea, select{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.hidden{ display: none; }

#form-container{
    margin-top: $base-component-height;

    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;

    padding: $default-margin;

    @media(max-width: $small-screen - 1){
        padding: $default-margin 0;
    }

    .icon{
        img{
            filter: iconFilter();
        }
    }
    
    .row{
        display: block;
    }

    .rowflex{
        display: flex;

        @media (max-width: $medium-screen - 1){
            display: block;

            .col{
                .input-container:first-child{
                    margin-top: 15px;
                }
            }
        }
    }

    .input-container{
        position: relative;
        text-align: left;

        label{
            display: block;
            transition: all .3s;
            color: $input-placeholder-color;
            position: absolute;
            top: 13px;
            left: 60px;
            pointer-events: none;
        }

        input.default::placeholder{
            color: transparent;
        }

        input.text-input.default:focus + label,
        input.text-input.default:not(:placeholder-shown) + label{
            transform: translateY(-23px);
            background-color: $middle-color2;
            color: $contrast-color2;
            padding: 0px 10px;
            border-radius: 8px;
        }

        input.text-input.default.no-icon + label{
            left: $space20;
        }

        input.text-input.file + label{
            display: none;
        }

        .customerPersonnel + label{
            transform: translateY(-23px);
            background-color: $middle-color2;
            color: $contrast-color2;
            padding: 0px 10px;
            border-radius: 8px;
            left: $space20;
        }
    }

    .col{
        position: relative;

        .input-container{
            &:first-child{
                margin-top: 0;
            }
        }
    }

    .default-padding-left{
        padding-left: $space20;

        @media (max-width: $medium-screen - 1){
            padding-left: 0;
        }
    }

    .default-padding-top{
        padding-top: $space20;
    }
    
    .top-ornament{
        position: absolute;
        top: 0;
        left: 0;
    }

    .bottom-ornament{
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #cancel{
        float: left;

        @media(max-width: $small-screen - 1){
            float: none;
        }

        background-color: transparent;

        &:hover{
            background-color: $base-button-border-color;
        }
    }

    #submit{
        float: right;

        @media(max-width: $small-screen - 1){
            margin-top: 5px;
            float: none;
        }
    }

    .full-width{
        width: 100%;
    }

    .photo-delete{
        position: absolute;
        cursor: pointer;

        width: 24px !important;
        height: 24px !important;

        border: 2px solid $primary-color1;
        border-radius: 12px;
        top: -12px;
        right: -12px;
        box-sizing: border-box;
    }

    .selectionStyle{
        @include selectionStyle();
        background-color: $primary-color1;

        @media (max-width: $small-screen - 1) {
            width: 100%;
        }
    }
}

.page-title{
    font: $large-font2;
    text-align: left;
    color: $contrast-color2;
    margin-bottom: $space20;
}

.right-align{
    text-align: right;
}

.default-margin-top{
    margin-top: 15px;
}

@import 'form-components/input';
@import 'form-components/select';
@import 'form-components/button';
@import 'form-components/option';
@import 'form-components/simpleMessage';
@import 'form-components/fieldMessage';
@import 'form-components/baseReactDatepicker';

@import 'form-components/joditEditor.scss';
@import 'form-components/messageStatus.scss';

#form-container{
    img{
        @media(max-width: $small-screen - 1){
            width: 100% !important;
            height: auto !important;
        }
    }
}