$clientPIC-description-font: $body-font1;
$clientPIC-component-width: 196px;
$clientPIC-image-form-width: 150px;

#content-container{
    @include DefaultContainerMaxWidth($clientPIC-component-width);
}

#clientPIC-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($clientPIC-component-width);

    @media (max-width: $small-screen - 1) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.clientPIC-container{
    display: inline-block;

    color: $description-font-color;
    font: $clientPIC-description-font;
    text-align: left;

    margin-left: $space20 + 3px;
    margin-top: $list-margin-top;
    vertical-align: top;
    box-sizing: border-box;

    max-width: $clientPIC-component-width;
    overflow: hidden;

    &:nth-child(1){
        margin-left: 0px;
    }

    @media(max-width: $small-screen - 1){
        margin-left: 0px;
    }

    @media(min-width: $small-screen) and (max-width: $medium-screen - 1){
        &:nth-child(2n+1){
            margin-left: 0px;
        }
    }

    @media(min-width: $medium-screen) and (max-width: $big-screen - 1){
        &:nth-child(3n+1){
            margin-left: 0px;
        }
    }

    @media(min-width: $big-screen){
        &:nth-child(4n+1){
            margin-left: 0px;
        }
    }    

    img{
        width: $member-component-width;
        height: 187px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    .clientPIC-id{
        font: Bold $large-font2;

        padding: 5px 20px;
        //background: amplifyColor($line-decoration-color, 5);

        .clientPIC-position{
            font-style: italic;
            color: $secondary-color;
        }

        .clientPIC-clientId{
            font-weight: lighter;
            font-style: italic;
            color: $secondary-color2;
        }
    }

    .clientPIC-options{
        margin-top: 5px;
        float: right;

        .status{
            margin-left: 5px;
        }
    }

    .clientPIC-description{
        margin-top: 10px;
        margin-bottom: 15px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
        padding: 0;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        margin-top: $default-margin;
    }
}

#form-container{
    img.clientPIC-profile-photo{
        cursor: pointer;   
        width: $client-component-width;
        height: $clientPIC-image-form-width;
        object-fit: cover;
        border-radius: $full-border-radius;
    }
}

.clientPIC-customStyle{
    .option-picName{
        font-weight: bold;
        font-size: 110%;
    }

    .option-companyName{
        font-style: italic;
        font-weight: bold;
        color: $secondary-color2;
    }
}