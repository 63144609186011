.supportingDocs-container{
    height: 300px;
    width: 400px;
    display: block;
    position: fixed;
    top: 103px;
    right: 0;
    z-index: 10;

    @media (max-width: $small-screen - 1) {
        height: 500px;
        width: 100%;
        top: 195px;
    }

    .dragdropArea{
        width: 100%;
        height: 100%;
    }

    .full-upload{
        width: 100%;
        height: 100%;
    }

    .hide{
        display: none; 
    }

    .chonky-baseButton[title="Options"]{
        display: none;
    }

    .rowflex{
        display: flex;
        //padding: 15px 15px 0px;

        &.center{
            align-items: center;
        }

        .col{
            display: flex;
            flex-wrap: wrap;

            &.left{
                flex: 0.4;
            }

            &.right{
                flex: 0.6;
            }

            &.default-margin-left{
                margin-left: $space20;
            }

            &.flex5{
                width: 50%;
            }

            &.flex1{
                width: 10%;
            }
        }        

        &.file-container{
            padding: 5px 20px;

            border-bottom: 1px solid $primary-color2;

            background-color: reduceColor($contrast-color2, 10);

            &.title{
                @include base-field-border("full");
                
                background-color: $contrast-color2;
                color: $primary-color2;
                font-weight: bold;
                font-size: 120%;
            }

            .name, .progress{
                color: $primary-color2;
            }

            a{
                color: $primary-color1;
            }

            &:hover{
                background-color: reduceColor($contrast-color2, 10);
            }

            &.hidden{
                display: none;
            }
        }

        &.data{
            flex-basis: 100%;

            div{
                flex: 1;
                text-align: right;

                &.fieldInformation-label{
                    flex: initial;
                    margin-left: 0;
                    text-align: left;
                }
            }
        }
    }

    .fileUploadList{
        color: $contrast-color2;
    }

    .dragHere{
        text-align: center;
        padding: 40px;
        font-size: 150%;
        font-weight: bold;

        @include base-field-background("full");
        border-style: dashed;
    }
}

.chonky-toolbarRight{
    @media (max-width: $small-screen - 1) {
        flex-wrap: wrap !important;
    }
}