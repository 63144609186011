$dbBackup-description-font: $body-font1;
$dbBackup-component-width: 100%;

#content-container{
    @include DefaultContainerMaxWidth($dbBackup-component-width);
}

#dbBackup-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($dbBackup-component-width);
}

#top-container{
    .dbBackup-options{
        display: flex;

        button{
            flex: 1;
        }

        button[name=upload]{
            margin-left: 15px;
        }
    }
}

.dbBackup-container{
    display: block;
    color: $description-font-color;
    font: $dbBackup-description-font;
    text-align: left;

    margin-top: $list-margin-top;
    padding: 12px;

    .dbBackup-name{
        font-weight: bolder;
        margin-bottom: 15px;
    }

    a{
        color: $secondary-color2;
        font-weight: bold;
        font-size: 120%;
    }

    .restore{
        float: right;
    }

    .dbBackup-Histories{
        .history{
            margin-top:12px;
            border: 1px solid lightgray;
            border-radius: 8px;
            padding: 12px;

            &.failed{
                background-color: $secondary-color3;
            }

            &.success{
                background-color: amplifyColor($secondary-color2, 40%);
            }
        }
    }
}

#DbRequestBackupAuth, #DbUploadBackup{
    .background{
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $primary-color1;
        opacity: 0.5;
    }

    #form-container{
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: $primary-color2;
        border-radius: 8px;

        .flex{
            display: flex;

            button{
                flex: 1;
            }
            button[name="cancel"]{
                margin-left: 20px;
            }
        }
    }
}