$base-component-height: 40px;
$default-margin: 30px;
$left-margin: $default-margin;

$space20: 20px;
$space60: 60px;

$decoration-height: 60px;
$footer-decoration-height: 30px;

$logo-height: 30px;

$title-left-margin: 80px;
$content-top-margin: 80px;
$nav-icon-height: 30px;

//Screen Size
$big-screen: 862px + (2*$default-margin);
$medium-screen: 700px;
$small-screen: 479px;