.message{
    height: $base-component-height;
    color: $primary-color1;
    background-color: $contrast-color1;
    font: $base-button-font;
    @include base-field-border("full");

    text-align: left;
    display: flex;
    align-items: center;
    padding-left: $base-field-left-padding;
    padding-right: $base-field-left-padding;
    margin-bottom: $base-field-left-padding;
    margin-top: 15px;

    &.update{
        position: fixed;
        z-index: 8002;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
    }
    
}