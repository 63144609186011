$fieldInformation-description-font: $body-font1;
$fieldInformation-component-width: 100%;

#fieldInformation-content-container{
    width: $fieldInformation-component-width;

    .pagination-component{
        width: $fieldInformation-component-width;
        max-width: $fieldInformation-component-width;
    }
}

#fieldInformation-list{
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    color: $normal-text-color;
    width: $fieldInformation-component-width;

    #form-container{
        width: 100%;
        padding: 0;

        .selectionStyle{
            width: 100%;
        }

        input[type="text"]{
            &.time{
                border: none;
                border-radius: 0;
                font-weight: bold;
                font-size: 140%;
                background-color: $primary-color2;
            }
        }

        .sun-editor, input[type=text]{
            background-color: transparent;
            box-sizing: border-box;

            &:not(.time):hover{
                @include base-field-border("full");
            }
        }
    }
}

.fieldInformation-container{
    display: inline-block;
    height: max-content;
    width: $fieldInformation-component-width;

    flex: 1;
    flex-basis: 100%;

    color: $description-font-color;
    font: $fieldInformation-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    .fieldInformation-header{
        padding: 15px 0;
    }

    .fieldInformation-label{
        font: $medium-font2-bold;
    }

    .fieldInformation-description{
        padding: 8px 12px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }

    input[type="text"]{
        &.time{
            border: none;
            border-radius: 0;
            font-weight: bold;
            font-size: 140%;
            background-color: $primary-color2;
        }
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color);
        border-width: 1px;
        position: relative;
        top: auto;
    }

    .rowflex{
        display: flex;
        //padding: 15px 15px 0px;

        &.center{
            align-items: center;
        }

        .col{
            display: flex;
            flex-wrap: wrap;

            &.left{
                flex: 0.4;
            }

            &.right{
                flex: 0.6;
            }

            &.default-margin-left{
                margin-left: $space20;
            }

            &.flex5{
                width: 50%;
            }

            &.flex1{
                width: 10%;
            }
        }        

        &.file-container{
            padding: 5px 20px;

            border-bottom: 1px solid $primary-color2;

            &.title{
                @include base-field-border("full");
                
                background-color: $primary-color2;
                color: $contrast-color2;
                font-weight: bold;
                font-size: 120%;
            }

            a{
                color: $contrast-color1;
            }

            &:hover{
                background-color: reduceColor($primary-color2, 10);
            }
        }

        &.data{
            flex-basis: 100%;

            div{
                flex: 1;
                text-align: right;

                &.fieldInformation-label{
                    flex: initial;
                    margin-left: 0;
                    text-align: left;
                }
            }
        }
    }

    .dragHere{
        text-align: center;
        padding: 40px;
        font-size: 150%;
        font-weight: bold;

        @include base-field-background("full");
        border-style: dashed;
    }
}

#download-container{
    @include float-container();

    opacity: 0.3;

    &:hover{
        opacity: 1;
    }
    
    button{
        width: 100px;
        height: 30px;
        border-radius: 8px;
        font: 18px $font1;
    }
}

#field-information-add{
    .container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        height: 100%;
        padding-top: 80px;
        display: block;
        box-sizing: border-box;
        overflow-y: scroll;
        z-index: 1;

        #form-container{
            width: 100%;
            box-sizing: border-box;
            background: $primary-color1;

            .sun-editor{
                background-color: transparent;
                box-sizing: border-box;

                &:not(.time):hover{
                    @include base-field-border("full");
                }
            }
        }
    }
}

.fieldInformation-delete{
        position: absolute;
        
        width: 20px;
        height: 20px;
        top: 10px;
        right: 20px;

        img{
            width: 100%;
            height: 100%;
        }
    }

/// MGKN BAKAL DIPISAH STYLINGNYA. JADI INI PEMBATAS UNTUK ACTION PLAN FIELDS
.actionPlanSection{
    min-width: 500px;
    max-width: 500px;

    @media (max-width: 600px) {
        min-width: auto;
        max-width: auto;
    }
    #actionPlanSectionContainer{
        background-color: $background-color;

        width: 100%;
        box-sizing: border-box;

        .row{
            width: 100%;
        }

        .actionPlanFields{
            width: 100%;

            .row{
                margin-top: 10px;
            }
        }
    }

    .textarea{
        background-color: $primary-color1;
    }
    
}