$pob-description-font: $body-font1;
$pob-component-width: 100%;
$pob-image-form-width: 500px;
$pob-image-form-height: 190px;

#pob-content-container{
    width: 100%;

    .pagination-component{
        max-width: 100%;
    }
}

#pob-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
}

.pob-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .pob-id{
        text-align: left;
        width: 100%;
        margin-top: $list-margin-top;
        font: $medium-font2-bold;
        color: $description-font-color;
    }
}

.pob-container-left{
    position: relative;
    display: inline-block;
    height: max-content;
    min-width: $pob-image-form-width;
    flex: 1;

    @media(max-width: $medium-screen - 1){
        width: 100%;
        min-width: auto;
    }

    color: $description-font-color;
    font: $pob-description-font;
    text-align: left;

    margin-left: 0px;
    overflow: hidden;
    
    img{
        width: 100%;
        height: auto;
    }

    .pob-pobHeader{
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        max-width: $pobHeader-image-form-width;
        height: 36px;
        background-color: $middle-color3;
        color: $contrast-color2;
        font: italic bold $body-font2;
        padding: 0 12px;
        border-radius: 8px 0px 8px 0px;
        display: flex;
        align-items: center;
    }

    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 0;
    }

    .row{
        display: flex;
        padding: 8px 12px;

        .col:first-child{
            flex: 1;
            text-align: left;
        }

        .col:last-child{
            flex: 1;
            text-align: right;
        }
    }

    
}

.pob-container-right{
    display: inline-block;
    position: relative;
    margin-left: $space20;
    min-width: 450px;
    box-sizing: border-box;

    @media(max-width: $medium-screen - 1){
        margin-left: 0px;
        width: 100%;
        min-width: auto;
    }

    flex: 1;

    text-align: left;
    padding: $space20;
    overflow-y: scroll;

    .pob-description{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
        padding: 8px 12px;
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
    }

    .field-description{
        text-align: left;
        font: bold $body-font2;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .pob-options{
        margin-top: 5px;
        display: flex;
        align-items: center;
        bottom: $space20;
        color: $contrast-color2;
        font: $body-font2;
        justify-content: end;
        
        @media(max-width: $small-screen - 1) {
            flex-direction: column;
            justify-content: center;
        }

        .option-container{
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        input[type=image]{
            margin-left: 15px;
            margin-right: 5px;

            &:first-child{
                margin-left: 0;
            }
        }
    }

    .latestStatus-date, .latestStatus-actionPlan{
        display: inline;
        font-weight: bold;
        margin-right: 15px;
    }

    .latestStatus-information{
        margin-top: 10px;
        font-weight: bold;
    }

    p{
        margin-top: 0;
        margin-bottom: 5px;
    }

    hr{
        border-color: $line-decoration-color;
    }
}

#form-container{
    .col{
        img.pob-profile-photo{
            cursor: pointer;
            width: 100%;
            height: $pob-image-form-height;
            object-fit: cover;
            border-radius: $full-border-radius;
        }
    }

    .fiscalYear, .budgetType{
        .spacing{
            width: $space20;    
            height: $space20;
        }

        input[type=text]{
            min-width: auto;
        }
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
    }

    
}

#pob{
    #form-container{
        .input-container{
            @media (max-width: $medium-screen - 1) {
                &:first-child{
                    margin-top: 15px;
                }
            }
            
        }
    }
}