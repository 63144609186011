$header-button-width: 120px;
$header-button-height: 30px;

#pob-content-header-container{
    width: 100%;
    position: fixed;
    display: flex;
    left: 0;
    top: 65px;

    @media (max-width: $medium-screen - 1) {
        top: 120px;
    }
    z-index: 2;

    .pob-content-header-contentList-menu{
        flex: 1;
        display: flex;
        height: $base-field-height;
        align-items: center;
        background-color: $primary-color2;
        border: 1px solid $middle-color2;
        box-sizing: border-box;
        overflow-x: scroll;

        &::-webkit-scrollbar{
            display: none;
        }

        .scrollable-container{
            display: flex;
            min-width: max-content;
        }

        .pobHeader-name, .contentList-name{
            display: flex;
            align-items: center;
            position: relative;

            color: $contrast-color2;
            padding: 0 20px;
            height: $base-field-height - 2;
            font: $pob-content-font;
            cursor: pointer;

            background-color: $primary-color2;

            &:hover{
                background-color: reduceColor($primary-color2, 10);
            }

            &.selected{
                background-color: reduceColor($primary-color2, 20);
            }
        }

        .contentList-name{
            .separator-block{
                width: 20px;
                height: 20px;
                background-color: $middle-color3;
                display: inline-block;
                border-radius: 4px;
                position: absolute;
                left: -10px;
                opacity: 0.5;
            }

            &:first-child{
                .separator-block{
                    opacity: 0;
                }
            }
        }
    }
}

#pobContent-container{
    #top-container{
        margin-top: 140px;

        @media (max-width: $medium-screen - 1) {
            margin-top: 195px;
        }
    }

    #below-nav{
        position: fixed;
        top: 104px;

        @media (max-width: $medium-screen - 1) {
            top: 159px;
        }
        right: 0px;
        z-index: 1;

        .update-button{
            display: inline-block;
            position: fixed;
            right: 0;
            top: 105px;

            @media (max-width: $medium-screen - 1) {
                top: 159px;
            }
            z-index: 2;
            background-color: amplifyColor($secondary-color2, 40%);

            width: $header-button-width;
            height: $header-button-height;
            
            border-radius: 0 0px 0px 8px;
            font: $pob-content-update-button-font;
        }
    }
}

//test drag and drop style
.block-content-area{
    margin-top: 104px;
    padding-top: 44px;

    .add-block-container{
        box-sizing: border-box;
        border: 1px dashed $middle-color3;
        color: $contrast-color2;
        padding: $space20;
        margin-top: $space20;
        text-align: center;
        

        .add-block-button-list{
            display: flex;
            align-items: center;
            margin: auto;
            width: max-content;

            .add-block-button{
                cursor: pointer;
                padding: 0 15px;

                img{
                    height: 20px;
                }
            }
        }
    }
}

.blockContent-container{
    width: 100%;
    position: relative;
    box-sizing: border-box;

    .draggable{
        padding: 5px;
        color: $contrast-color2;

        cursor: pointer;

        .input-container{
            margin-top: 0;
        }

        &:hover{
            //border: 1px dashed $middle-color3;
            background-color: $primary-color1;
            border-radius: 8px;
        }

        &.dragging{
            opacity: 0.5;
            background-color: $middle-color2;
            height: 60px;
            padding: 20px;
            overflow: hidden;
        }
    }
}

.block-content-container{
    position: relative;
    display: flex;
    flex-flow: wrap;

    .menu{
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        
        .remove{
            width: 20px;

            img{
                width: 20px;
            }
        }

        .changeOrder{
            margin-left: 20px;
            width: 20px;
            height: 20px;

            background-image: url(../img/icon-move.png);
            background-repeat: no-repeat;

            img{
                width: 20px;
            }
        }
    }

    .content-editor{
        flex: 1;
    }

    .content-update-history{
        flex-basis: 100%;
        color: $secondary-color;
        font-size: 80%;
        text-align: right;
        font-style: italic;
    }
}

.status{
    color: white;
}

#download-component-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    .daterange-filter{
        position: fixed;
        top: 104px;
        right: 120px;
        width: fit-content;
        padding: 0 20px 20px 20px;
        background-color: white;
        border: 2px solid $line-decoration-color;
    }
}

.pobContentTypeLabel {
    font-size: 12px;
    color: $primary-color1;
    background-color: $contrast-color1;
    border-radius: 10px;
    padding: 5px 20px;
}