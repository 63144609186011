$customerPersonnel-description-font: $body-font1;
$customerPersonnel-component-width: 196px;
$customerPersonnel-component-height: 187px;
$customerPersonnel-image-form-width: 380px;
$customerPersonnel-image-form-height: 370px;

#content-container{
    @include DefaultContainerMaxWidth($customerPersonnel-component-width);
}

#customerPersonnel-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($customerPersonnel-component-width);
}

.customerPersonnel-container{
    display: flex;
    width: 100%;

    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
    }
}

.customerPersonnel-container-left{
    display: inline-block;
    height: max-content;
    width: $customerPersonnel-component-width;
    
    @media(max-width: $medium-screen - 1){
        flex: 1;
    }

    color: $description-font-color;
    font: $customerPersonnel-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    img{
        width: $customerPersonnel-component-width;
        height: $customerPersonnel-component-height;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    .customerPersonnel-description{
        padding: 8px 12px;
    }

    .customerPersonnel-options{
        margin-top: 5px;
        float: right;
        display: flex;

        input:not(:first-child){
            margin-left: 5px;
        }
    }
}

.customerPersonnel-container-right{
    display: inline-block;
    margin-left: $space20;
    margin-top: $list-margin-top;

    @media(max-width: $medium-screen - 1){
        margin-left: 0px;
        width: 100%;
    }

    flex: 1;

    text-align: left;
    padding: $space20;

    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
    
    max-height: 350px;
    .customerPersonnel-note, .customerPersonnel-description{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;

        &.sun-editor-content{
            max-height: max-content !important;
        }

        img{
            width: 100%;
        }
        
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .message{
        margin-top: 0;
    }

    input[type=text]{
        min-width: auto;
    }

    .rowGrid{
        display: flex;
        flex-wrap: wrap;
        font: $body-font1;
        color: $normal-text-color;

        .col{
            flex: 1;
            flex-basis: 100%;
            display: flex;

            border-bottom: 1px solid $description-font-color;

            .field{
                flex: 0.5;
                text-align: left;
                padding: 5px 0px;
            }

            .value{
                padding: 5px;
                border-left: 1px solid $description-font-color;
                text-align: right;
            }
        }
    }
    .two-col{
        display: flex;
        flex-wrap: wrap;
        font: $body-font1;
        color: $normal-text-color;

        .col{
            flex: 0.5;
            display: flex;
            padding: 5px;

            border-bottom: 1px solid $description-font-color;

            .field{
                flex: 1;
                text-align: left;
            }

            .value{
                text-align: right;
            }

            &:first-child{
                padding-left: 0;
            }

            &:last-child{
                padding-right: 0;
                border-left: 1px solid $description-font-color;
            }
        }
    }
}

#form-container{
    .col{
        width: 100%;

        &.title{
            padding-right: 20px;
            align-self: center;

            .page-title{
                margin-bottom: 0;
            }
        }

        &.profile-history-toggle{
            flex: 1;
            text-align: right;
        }

        &.history-add-edit-toggle{
            width: auto;
            text-align: right;
        }

        &.profile{

            .profileContainer{
                position: relative;
                width: $customerPersonnel-image-form-width;
                text-align: center;
                margin: auto;
                margin-top: $space20;

                @media (max-width: $small-screen - 1) {
                    width: 100%;
                }

                img.customerPersonnel-profile-photo{
                    cursor: pointer;
                    width: $customerPersonnel-image-form-width;
                    height: $customerPersonnel-image-form-height;
    
                    object-fit: cover;
    
                    border-radius: $full-border-radius;
                }
            }
        }

        &.flex1{
            flex: 1;
        }

        
    }

    .invisible{
        visibility: hidden;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .custom-0-z-index{
        z-index: 0;
    }

    .hidden{
        display: none;
    }

    button.round{
        margin-left: $base-field-left-padding;
        width: 40px;
        height: 40px;
        border-radius: 20px;

        &.all-center{
            display: flex;
            align-items: center;
        }

        .add-or-edit-icon{
            width: 15px;
            height: 15px;
            margin: auto;
        }

        img{
            width: 15px;
            height: 15px;
        }
    }

    .react-datepicker-wrapper{
        display: block;
    }

    .add-history-title-container{
        margin-bottom: 20px;
        align-items: center;
        display: flex;
        height: 40px;

        &.hidden{
            display: none;
        }

        .add-history-title{
            font: $medium-font2-bold;
            color: $description-font-color;
        }
    }

    .rowflex.two-field{
        @media(min-width: $medium-screen){
            .input-container{
                width: 50%;
    
                input[type=text]{
                    min-width: auto;
                }
    
                &:not(:first-child){
                    margin-left: 15px;
                }
            }
        }
    }

    .title{
        margin-top: 15px;
        text-align: left;
        font: $medium-font2-bold;
        color: $description-font-color;
    }

    .rowflex{
        .spacing{
            margin-left: 15px;
        }
    }

    .positionList{
        .input-container.position{
            img{
                position: absolute;
                right: -10px;
                top: -10px;
            }
        }
    }
}

//customer personnel import
.importButton{
    margin-right: 20px;
}

#import-customer-personnel-container{

    &.import-open{
        display: flex;
        align-items: center;
    }

    position: fixed;
    top: 0;
    left: 0;
    z-index: 8002;
    width: 100%;
    height: 100%;

    .background{
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.6;
        background-color: $primary-color1;
        width: 100%;
        height: 100%;
    }

    #form-container{
        background-color: $background-color;
        border-radius: 25px;

        button{
            border: 1px solid #707070;
            border-radius: 8px;
            width: 160px;
            height: 40px;
            font: Bold 15px/18px Roboto;
            cursor: pointer;
        }
    }
}

#customerPersonnel-list table{
    width: 100%;

    thead{
        background-color: $contrast-color1;
        color: $primary-color1;

        tr{
            font-size: large;

            th {
                padding: 10px;
            }
        }
    }

    tbody{
        text-align: left;

        tr{
            &:hover{
                cursor: pointer;
                background-color: $primary-color2;
            }

            td {
                padding: 10px;
            
                &.customerPersonnel-options{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                }
            }
        }
    }
}