@import "_size";

@mixin float-container($position: "left"){
    position: fixed;
    z-index: 1;

    margin-left: 0px;
    bottom: $footer-decoration-height + $space20;

    @if $position == "right"{
        right: $space20;
    }
    @else{
        left: $space20;
    }
    
}

#top-container{
    margin-top: $space20; 

    #search-container{
        display: flex;

        @media (max-width: $small-screen - 1) {
            flex-direction: column;
        }

        input{
            flex: 1;
        }

        input, select{
            min-width: 200px;
            width: auto;
        }
    }
}

#add-menu-container{
    @include float-container("right");

    button{
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font: 30px $font1;
    }
    
}

#left-top-menu-container{
    text-align: left;
    margin-top: $space20;
}

#help-menu-container{
    @include float-container();

    button{
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font: 30px $font1;

        &#help.on{
            background-color: amplifyColor($secondary-color2, 40%);
        }
    }

    
}

.helper-data{
    display: none;
}

body.show-helper{

    .input-container:hover{
        .helper-data{
            display: block;
            color: $contrast-color2;
            font-weight: bold;
            background-color: $secondary-color3;
            max-width: 200px;
            word-wrap: normal;
            padding: 5px;

            height: max-content;
            position: absolute;
            top: -20px;
            right: 0;
        }
    }
    
}