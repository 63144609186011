$pobHeader-description-font: $body-font1;
$pobHeader-component-width: 100%;
$pobHeader-image-form-width: 500px;
$pobHeader-image-form-height: 190px;

#pobHeader-content-container{
    width: 100%;

    .pagination-component{
        max-width: 100%;
    }
}

#pobHeader-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
}

.pobHeader-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
    }

    @media(max-width: $small-screen - 1){
        flex-wrap: nowrap;
    }

    .pobHeader-id{
        text-align: left;
        width: 100%;
        margin-top: $list-margin-top;
        font: $medium-font2-bold;
        color: $description-font-color;
    }

    &.compact{
        margin-top: $list-margin-top;
        
        .pobHeader-id{
            margin-top: 0
        }

        img{
            width: 150px;
            height: 57px;
            object-fit: cover;
        }

        .row{
            display: flex;
            width: 100%;

            &.v-baseline{
                align-items: baseline;
            }

            .pad-5{
                padding: 5px;
            }
        }

        .active{
            color: $secondary-color2;
        }

        .not-active{
            color: $secondary-color;
        }
    }
}

.pobHeader-container-left{
    display: inline-block;
    height: max-content;
    box-sizing: border-box;
    min-width: 500px;
    flex: 1;
    
    @media(max-width: $medium-screen - 1){
        min-width: auto;
        width: 100%;
        flex: 1;
    }

    color: $description-font-color;
    font: $pobHeader-description-font;
    text-align: left;

    margin-left: 0px;
    overflow: hidden;

    img{
        width: 100%;
        height: auto;
    }

    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 0;
    }

    .row{
        display: flex;
        padding: 8px 12px;

        .col:first-child{
            flex: 1;
            text-align: left;
        }

        .col:last-child{
            flex: 1;
            text-align: right;
        }
    }

    
}

.pobHeader-container-right{
    display: inline-block;
    position: relative;
    margin-left: $space20;
    box-sizing: border-box;
    min-width: 450px;

    @media(max-width: $medium-screen - 1){
        margin-left: 0px;
        width: 100%;
        min-width: auto;
    }

    flex: 1;

    text-align: left;
    padding: $space20;
    overflow-y: scroll;

    .pobHeader-description{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
        padding: 8px 12px;

        @media (max-width: $small-screen - 1) {
            margin-bottom: 80px;
        }
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
    }

    .field-description{
        text-align: left;
        font: bold $body-font2;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .pobHeader-options{
        margin-top: 5px;
        display: flex;
        align-items: center;
        bottom: $space20;
        color: $contrast-color2;
        font: $body-font2;
        justify-content: end;

        @media(max-width: $small-screen - 1){
            flex-direction: column;
            justify-content: center;
        }

        .option-container{
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 20px;
            
            @media(max-width: $small-screen - 1){
                margin-top: 5px;
            }
        }

        select{
            min-width: 200px;
        }

        input[type=image]{
            margin-left: 15px;
            margin-right: 5px;

            &:first-child{
                margin-left: 0;
            }
        }
    }

    .latestStatus-date, .latestStatus-actionPlan{
        display: inline;
        font-weight: bold;
        margin-right: 15px;
    }

    .latestStatus-information{
        margin-top: 10px;
        font-weight: bold;
    }

    p{
        margin-top: 0;
        margin-bottom: 5px;
    }

    hr{
        border-color: $line-decoration-color;
    }
}

#form-container{
    .col{
        img.pobHeader-profile-photo{
            cursor: pointer;
            width: 100%;
            height: $pobHeader-image-form-height;
            object-fit: cover;
            border-radius: $full-border-radius;
        }
    }

    .fiscalYear, .budgetType{
        .spacing{
            width: $space20;
            height: $space20;    
        }

        input[type=text]{
            min-width: auto;
        }
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
    }

    .input-container.budgetType{
        display: flex;

        img{
            width: 20px !important;
            height: 20px !important;
            align-self: center;
            margin-left: $space20;
            cursor: pointer;
        }
    } 
}

.pobHeader-container-separator{
    text-align: center;
    display: block;
    margin-top: $space20;
    flex: 1;
    flex-basis: 100%;

    .separator-block{
        width: 20px;
        height: 20px;
        background-color: $middle-color3;
        display: inline-block;
        margin-left: 15px;
        border-radius: 4px;

        &:first-child{
            margin-left: 0px;
        }
    }
}