@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,400&display=swap');

$font1: Roboto;
$font2: Rubik;

//FONT 1
$body-font1: 14px/19px $font1;
$medium-font1-bold: Bold 15px/18px $font1;
$input-font1: 14px/17px $font1;

$simple-message-font1-bold: Bold 15px/20px $font1;

//FONT 2
$body-font2: 14px/22px $font2;
$medium-font2-bold: Bold 16px/19px $font2;
$large-font2: 18px/22px $font2;
$nav-small-font: 11px/22px $font2;
$extra-large-font2: 20px/24px $font2;
$footer-font: Italic 14px/16px $font2;
$pob-content-font: 16px/22px $font2;
$pob-content-update-button-font: Bold 18px/22px $font2;