#print-personnels-container{
    @include base-field-border("full");
    padding: 10px 0px 20px 0;
    margin-top: 10px;

    ul.personnel-list{
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin-top: 0;

        li{
            display: flex;
            margin-top: 10px;
            margin-left: 20px;
            padding: 5px 15px;
            background-color: $contrast-color1;
            font-weight: bold;
            font-style: italic;

            .remove{
                margin-left: 10px;
                cursor: pointer;
                color: $secondary-color;

                &:hover{
                    color: $primary-color1;
                }
            }
        }
    }

    .clear-all, .preview{
        background: transparent;
    }

    .preview{
        margin-left: 20px;
    }
}

#preview-container{
    display: block;
    position: fixed;
    z-index: 10000;

    background: white;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    padding: 20px;
    overflow: scroll;
    font: $body-font1;

    box-sizing: border-box;

    .printPreviewMenu{
        display: flex;
        position: fixed;
        top: 20px;
        right: 40px;

        button{
            width: 40px;
            border-radius: 20px;

            &#remove{
                margin-left: 15px;
            }
        }

        #print{
            @include base-button-border();
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 20px;
            background-color: $button-background-color;
            cursor: pointer;

            img{
                height: 15px;
                margin: auto;
            }

            div{
                margin: auto;
                font: $medium-font1-bold;
                color: $contrast-color1;
            }
        }
    }

    .row{
        display: flex;
        align-items: center;
    }

    .col{
        flex: 1;
    }

    .personnel{
        margin-top: 20px;
    }

    #preview-title{
        font: $h1-font;
        margin-bottom: 20px;
    }

    .title{
        font-size: 110%;
        font-weight: bold;
        margin-top: 20px;
    }

    .basic-info{
        .profile{
            flex: 0.3;
            text-align: left;
        }

        .photo{
            img{
                height: 80px;
            }
        }

        .name{
            font: bold $large-font2;
        }
    }

    .basic-info-data{
        width: max-content;

        .row{
            border-bottom: 1px solid gray;
        }

        .col{
            &:not(:first-child){
                border-left: 1px solid gray;
            }

            flex-basis: 100%;
        }
    }

    .position-history{
        text-align: left;

        .row{
            border-bottom: 1px solid gray;
            width: 100%;
            max-width: 500px;
        }

        .field{
            flex: 1;
            flex-basis: 100%;
        }

        .value{
            margin-left: 50px;
            flex-basis: 100%;
            text-align: right;
        }
    }

    .description{
        text-align: left;
    }
}

.overflow-hidden{
    overflow: hidden;
}

.customerPersonnel-options{
    .option.add-to-print{
        @include base-button-border();
        background-color: $middle-color3;
        width: 30px;
        height: 20px;
        border-radius: 30px;
        color: $background-color;
        font: $medium-font1-bold;
        cursor: pointer;
    }
}

@media print{
    .content{
        margin: 0;
        padding: 0;
    }

    body{
        background-color: transparent;
    }

    header, footer, #content-container, #top-container, #add-menu-container{
        display: none;
    }

    #print-personnels-container{
        border: none;
        margin: 0;
        padding: 0;
        ul.personnel-list, button{
            display: none;
        }
    }

    #preview-container{
        position: static;

        .printPreviewMenu{
            display: none;
        }

        width: 100%;
        height: 100%;
        padding: 0;
        overflow: visible;
    }

    #import-customer-personnel-container{
        display: none;
    }
}