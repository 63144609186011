.addblock-law-regulation{
    .background{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        background-color: $primary-color1;
        opacity: 0.5;

        align-items: center;
        z-index: 8002;
    }
    
    #form-container{
        @include base-field-background();
        position: fixed;
        width: auto;
        max-width: 420px;
        height: 530px;
        text-align: center;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding: $space20;

        z-index: 8003;

        @media(max-width: $small-screen - 1){
            top: 74px;
        }

        .pagination-component{
            margin-top: 5px;
        }

        .default-margin-top{
            margin-top: 15px !important;
        }

        .row.lawRegulation-header{
            display: flex;
        }

        .line-decoration{
            top: 64px;
        }

        .col{
            &.size150{
                width: 150px;
                height: 150px;
            }

            &.photo-options{
                padding: $space20;

                button{
                    display: block;
                    width: 100%;
                    background-color: $primary-color1;
                    margin-bottom: 5px;

                    &:hover{
                        background-color: reduceColor($primary-color1, 10);
                    }

                    &.selected{
                        background-color: transparent;
                        border-width: 4px;
                    }
                }
            }
        }

        img.photo{
            width: 150px;
            height: 150px;
            object-fit: cover;
            @include base-field-border("full");
            border-width: 2px;
            box-sizing: border-box;
        }

        .customerPersonnel-description{
            @include base-field-border("full");
            border-style: dashed;
            border-width: 2px;
        }
    }
}

.block-content-area{
    .lawRegulation-container{
        flex-basis: auto;
        margin-top: 0;
        margin: auto !important;
    }
}