@mixin DefaultContainerMaxWidth($component-width){
    @media(max-width: $small-screen - 1){
        width: 100%;
    }

    @media(min-width: $small-screen) and (max-width: $medium-screen - 1){
        width: 100%;
    }

    @media(min-width: $medium-screen) and (max-width: $big-screen - 1){
        width: $medium-screen - (2*$default-margin);
    }

    @media (min-width: $big-screen) {
        width: $big-screen - (2*$default-margin);
    }
}

#content-container{
    margin-left: auto;
    margin-right: auto;
}