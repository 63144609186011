.field-message{
    @include base-button-border();
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 2px $space20;
    background-color: $contrast-color2;
    text-align: center;
}

.field-message::after{
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    border-color: $contrast-color2 transparent transparent transparent;
    border-width: 8px;
    border-style: solid;
}