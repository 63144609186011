@mixin decoration($height){
    width: 100%;
    height: $height;
    background-color: $background-color2
}

@mixin line-decoration($line-decoration-color, $width: 2px){
    width: 100%;
    height: 0px;
    border: $width solid $line-decoration-color;
    box-sizing: border-box;
}