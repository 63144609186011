//radio
.option-input{
    @include base-field-background();
    height: $base-field-height;
    display: flex;
    background-color: transparent;

    @media (max-width: $medium-screen - 1){
        margin-top: 15px;
        margin-bottom: 15px;
    }

    @media (min-width: $medium-screen){
        margin-top: 27px;
    }

    .option{
        display: none;
        overflow: hidden;

        &:checked + label{
            background-color: $middle-color1;
            @include base-field-background();
            color: $contrast-color2;
        }
    }

    label{
        vertical-align: middle;
        flex: 1;        
        font: $large-font2;
        color: $middle-color3;
        padding: 9px 0;
    }
}