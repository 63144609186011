#history-list > #form-container{
    max-width: 100%;
    width: 100%;
    padding: 0;
}

#form-container{
    .history-container{
        max-width: 100%;
        width: 100%;
        padding: $default-margin;
        border-width: 2px;
        box-sizing: border-box;
    }
    .history-when{
        align-items: center;

        @media (max-width: $medium-screen - 1) {
            display: flex;
        }

        .input-container{
            margin-top: 0;
        }
        select{
            max-width: 200px;
        }
        input{
            height: $base-field-height;
            border: 1px solid #EEE;
            border-radius: 0px;
            box-shadow: 0px 1px 3px #0000000a;
            width: 100%;
        }
        .history-delete{
            margin-left: $default-margin/2;

            img{
                width: 24px !important;
                height: 24px !important;
            }
        }
    }

    .history-description{
        h2{
            text-align: left;
        }
    }
}