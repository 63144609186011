$lawRegulation-description-font: $body-font1;
$lawRegulation-component-width: (($big-screen - (2*$default-margin)) / 2) - $space20;

#content-container{
    @include DefaultContainerMaxWidth($lawRegulation-component-width);
}

#lawRegulation-list{
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    color: $normal-text-color;

    @include DefaultContainerMaxWidth($lawRegulation-component-width);
}

.lawRegulation-container{
    display: inline-block;
    height: max-content;
    overflow: hidden;
    width: $lawRegulation-component-width;

    @media(min-width: $big-screen){
        &:nth-child(2n){
            margin-left: $default-margin;
        }
    }
    
    @media(max-width: $big-screen - 1){
        flex: 1;
        flex-basis: 100%;
    }

    color: $description-font-color;
    font: $lawRegulation-description-font;
    text-align: left;

    margin-left: 0px;
    margin-top: $list-margin-top;

    .lawRegulation-header{
        background-color: $line-decoration-color;
        align-items: center;

        padding: 12px;

        .icon{
            img{
                width: auto;
                height: 15px;
            }
        }
        
        .ruleType{
            text-transform: capitalize;
            font-style: italic;
            font-weight: bold;
        }

        .ruleDate{
            text-align: left;
            font-style: italic;
            color: $secondary-color2;
        }
    }

    .lawRegulation-id{
        font: $body-font2
    }

    .lawRegulation-name{
        font: $medium-font2-bold
    }

    .lawRegulation-description{
        padding: 8px 12px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;

        p{
            margin: 0;
        }

        input[name=file_description]{
            width: 100%;
        }
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color);
        border-width: 1px;
        position: relative;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        &.space{
            margin-top: 15px;
        }
    }

    .lawRegulation-tag-list{
        display: flex;
        flex-wrap: wrap;

        .tagItem{
            padding: 2px 8px;
            background-color: $middle-color2;
            border-radius: 2px;
            margin: 2px;
        }
    }

    .lawRegulation-options{
        margin-top: 15px;
        float: right;
    }
}

#form-container{
    .icon img{
        width: auto;
        height: 15px;
    }

    .text-input.lawRegulation-tags{
        width: 100%;
    }

    .col.right-aligned{
        text-align: right;
        position: relative;

        @media (min-width: $medium-screen) {
            .input-container{
                display: inline-block;
            }
        }
        

        input[name=file_description]{
            cursor: pointer;
        }

        .file-delete{
            cursor: pointer;
            position: absolute;
            top: -$space20/2;
            right: -$space20/2;
        }
    }
}