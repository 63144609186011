@import 'theme';
@import 'include/_topContainer';
@import 'include/_centerHelper';
@import 'form';

@import 'leftNav';
@import 'pagination';
@import 'login';
@import 'dashboard';

@import 'actionPlan';
@import 'member';
@import 'client';
@import 'clientPIC';
@import 'customer';
@import 'customerPersonnel';
@import 'customerPersonnelPrint';
@import 'lawRegulation';
@import 'pobHeader';
@import 'pob';
@import 'pobContent';
@import 'fieldInformation';
@import 'latestStatus';
@import 'news';
@import 'dbBackup';
@import 'history';
@import 'tnialviewer';
@import 'supportingDocs';

//add-block styling
@import 'add-block/addBlockCustomerPersonnel.scss';
@import 'add-block/addBlockLawRegulation.scss';

//temporary here
.container-separator-block{
    text-align: center;
    display: block;
    margin-top: $space20;
    flex: 1;
    flex-basis: 100%;

    .separator-block{
        width: 20px;
        height: 20px;
        background-color: $middle-color3;
        display: inline-block;
        margin-left: 15px;
        border-radius: 4px;

        &:first-child{
            margin-left: 0px;
        }
    }

    .nextButton{
        cursor: pointer;
        display: block;
        margin: 20px auto auto auto;
    }
}

//popup container
.popup-container{
    .background{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        background-color: $primary-color1;
        opacity: 0.5;

        align-items: center;
        z-index: 8002;
    }
    
    #form-container{
        @include base-field-background();
        position: fixed;
        width: auto;
        max-width: 420px;
        height: 530px;
        text-align: center;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding: $space20;

        z-index: 8003;
    }
}

button:disabled {
    background-color: $base-button-text-color;
}

.external-document{
    border: none;
    width: 100%;
    height: max-content;
}

a.clickable{
    cursor: pointer;
    text-decoration: underline;
}

button.Toastify__close-button{
    width: auto;
    height: auto;
}