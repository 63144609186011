$pagination-font: $body-font1;
$pagination-max-width: ($big-screen - (2*$default-margin));
$pagination-button-width: 50px;

.pagination-component{
    display: flex;
    margin-top: $space60;
    text-align: center;
    max-width: $pagination-max-width;
    margin-left: auto;
    margin-right: auto;

    &:last-child{
        margin-bottom: $space60;
    }
}

.pagination-button{
    background-color: black;
    height: $base-component-height;
    width: $pagination-button-width;

    img{
        vertical-align: middle;
    }
}

.pagination-description{
    height: $base-component-height;
    display: inline-table;
    vertical-align: top;
    box-sizing: border-box;
    text-align: left;
    flex: 1;

    font: $pagination-font;
    color: $pagination-text-color;

    padding: 0 $space20;

    span{
        display: table-cell;
        vertical-align: middle;
    }
    
}