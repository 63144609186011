$decoration-height: 110px;

body{
    background-color: $background-color;
}

#loginContainer{
    @include center(100%, 100vh);
    position: absolute;
    display: grid;

    max-width: 420px;
    width: 100%;

    @media screen and (max-width: 768px){
        .formContainer{
            padding: 20px;
        }
    }
}

#logoContainer{
    margin-bottom: 10px;
    text-align: center;

    #logo{
        width: 150px;
    }
}

div.input-container{
    margin-top: 15px;
}

#login-button{
    float: right;
}

.decoration-top{
    @include decoration($decoration-height);
    position: absolute;
    top: 0px;
}

.line-decoration-top{
    @include line-decoration($line-decoration-color);
    position: absolute;

    top: $decoration-height + 4px;
}

@media screen and (min-width: 768px){
    .decoration-bottom {
        @include decoration($decoration-height);
        position: absolute;
        bottom: 0px;
    }

    .line-decoration-bottom {
        @include line-decoration($line-decoration-color);
        position: absolute;

        bottom: $decoration-height + 4px;
    }
}

input.text-input.login + label{
    display: none;
}