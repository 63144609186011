$customer-description-font: $body-font1;
$customer-component-width: 196px;
$customer-image-form-width: 150px;

#content-container{
    @include DefaultContainerMaxWidth($customer-component-width);
}

#customer-list{
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include DefaultContainerMaxWidth($customer-component-width);
}


.customer-container > div{
    padding: 0 $space20;

    &.customer-note{
        padding: 0;

        .customer-note-content{
            padding: $space20 $space20 0;
        }
    }
}

.customer-container{
    display: block;
    width: 100%;

    color: $description-font-color;
    font: $customer-description-font;
    text-align: left;

    margin-top: $list-margin-top;
    padding: 0 0 15px 0;

    @media(max-width: $medium-screen - 1){
        align-items: center;
        flex-direction: column;
    }

    box-sizing: border-box;

    .customer-id{
        font: Bold $large-font2;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        background: amplifyColor($line-decoration-color, 5);

        .name{
            flex: 1;
            text-align: left;
        }
    }

    .customer-tags{
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;

        .tag{
            padding: 5px 20px;
            background-color: $contrast-color1;
            color: $primary-color1;
            margin-left: 5px;
            font-style: italic;
        }
    }

    .customer-options{
        margin-top: 5px;
        float: right;

        .status{
            margin-left: 5px;
        }
    }

    .customer-description{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font: $body-font2;
        color: $contrast-color2;
    }
    
    .line-decoration{
        @include line-decoration($line-decoration-color, 1px);
        position: relative;
        top: 5px;
        padding: 0;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        margin-top: $default-margin;
    }

    .sun-editor-content{
        margin-top: 5px !important;
    }
}

#form-container{
    .tagDescription{
        font: $input-font1;
        color: $contrast-color2;
        font-style: italic;
    }

    .field-description{
        text-align: left;
        font: $medium-font2-bold;
        color: $contrast-color2;
    
        margin-top: $default-margin;
    }
}